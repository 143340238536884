import Icon from '@ant-design/icons';

export function Sun() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z">
                        </path>
                    </svg>
                )
            }
        />
    );
}

export function Moon() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z">
                        </path>
                    </svg>
                )
            }

        />

    );
}

export function Logo({ width = 200, height = 60 }) {
    return (
        <Icon
            component={
                () => (
                    <svg id="Group_11027" data-name="Group 11027" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 168.686 58.702" fontSize={50}>
                        <text id="AR" transform="translate(38.666 46.553)" fill="#003d78" fontSize="32" fontFamily="Poppins-Bold, Poppins" fontWeight="700"><tspan x="0" y="0">AR</tspan></text>
                        <text id="ENZY" transform="translate(86.686 46.553)" fill="#f9423a" fontSize="32" fontFamily="Poppins-Bold, Poppins" fontWeight="700"><tspan x="0" y="0">ENZY</tspan></text>
                        <path id="Path_160" data-name="Path 160" d="M7.359,14.819c.98-3.79,1.982-7.574,2.927-11.372A3.22,3.22,0,0,1,12.774.771a3.859,3.859,0,0,1,3.77,1.076,2.8,2.8,0,0,1,.814,2.9,42.788,42.788,0,0,0-1.2,5.621c.528-.738,1.06-1.474,1.585-2.215q2.344-3.308,4.686-6.619a3.343,3.343,0,0,1,4.94-.786,3.3,3.3,0,0,1,.83,4.838q-3.861,5.445-7.72,10.89c-.172.243-.333.493-.581.861a23.769,23.769,0,0,1,7.279.992,21.276,21.276,0,0,1,8.816,5.441c.5.5.655.782.017,1.342-1.311,1.15-2.543,2.4-3.764,3.644-.493.5-.749.6-1.32.006a13.739,13.739,0,0,0-20.054-.062A12.811,12.811,0,0,0,7.762,42.194a13.007,13.007,0,0,0,10.461,9.221A13.337,13.337,0,0,0,31.082,47.2c.408-.43.593-.433,1-.014,1.285,1.33,2.6,2.635,3.948,3.9.487.455.424.706-.011,1.133-5.923,5.81-12.95,7.855-20.915,5.583C7.173,55.532,2.292,50.123.42,42.065A18.757,18.757,0,0,1,0,37.782Q.016,24.21.008,10.637A6.967,6.967,0,0,1,.067,9.408,3.688,3.688,0,0,1,3.849,6.2a3.824,3.824,0,0,1,3.316,3.842c.053,1.573.011,3.15.011,4.725l.182.049" transform="translate(0 0)" fill="#003d78" />
                        <text id="let_us_Fix_Your_Need" data-name="let us Fix Your Need" transform="translate(39.057 56.644)" fill="#f9423a" fontSize="7" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.41em"><tspan x="0" y="0">LET US FIX </tspan><tspan y="0" fill="#003d78">YOUR NEED</tspan></text>
                        <g id="Group_12" data-name="Group 12" transform="translate(11.655 29.552)">
                            <path id="Path_141" data-name="Path 141" d="M17.023,5.079a.348.348,0,0,0,.071.453,1.6,1.6,0,0,1,.418,1.311.427.427,0,0,0,.062.3,1.5,1.5,0,0,1,.163,1.446c-.08.187.131.274.127.411a2.165,2.165,0,0,1-.147,1.216c-.185.292.041.621-.08.961a7.475,7.475,0,0,1-.808,1.953A13.829,13.829,0,0,1,16,14.461,9.356,9.356,0,0,1,14.7,15.782a6.16,6.16,0,0,1-1.579,1.078c-.578.187-1.074.632-1.683.644-.564.012-1.03.375-1.587.332a8.113,8.113,0,0,1-1.879.013c-.572-.188-1.211-.056-1.72-.371-.485-.3-1.107-.277-1.525-.652-.39-.35-.9-.481-1.282-.827a9.25,9.25,0,0,1-1.325-1.291,6.4,6.4,0,0,1-1.1-1.616C.834,12.531.4,12.044.4,11.452.391,10.93.062,10.51.078,10c.007-.233-.147-.474.078-.692.054-.052.017-.168-.017-.262A1.527,1.527,0,0,1,.207,7.639C.323,7.457.127,7.3.172,7.177A3.969,3.969,0,0,1,.618,5.9c.2-.258.146-.718.361-1.034.117-.172.109-.461.427-.468.044,0,.1-.144.126-.229a1.706,1.706,0,0,1,.9-1.16.438.438,0,0,0,.23-.215,1.542,1.542,0,0,1,1.046-.948.492.492,0,0,0,.229-.2A1.576,1.576,0,0,1,5.19.94c.086,0,.214-.025.251-.083A1.181,1.181,0,0,1,6.476.387c.534-.014.969-.359,1.5-.326.2.013.414-.126.6.087.054.061.19,0,.3-.03A1.609,1.609,0,0,1,10.247.19c.064.04.168.092.216.066a1.191,1.191,0,0,1,1.171.17c.432.243.933.249,1.336.52.2.132.487.146.538.467.011.067.159.143.255.162.523.1.793.527,1.173.825a8.316,8.316,0,0,1,1.4,1.549,7.954,7.954,0,0,1,.689,1.129" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M35.195,28.933a6.24,6.24,0,1,1-6.228,6.262,6.209,6.209,0,0,1,6.228-6.262" transform="translate(-26.257 -26.226)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,39a5.845,5.845,0,1,1,5.859,5.842A5.859,5.859,0,0,1,33.188,39" transform="translate(-30.083 -30.051)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M116.891,84.054a5.639,5.639,0,0,1-1.671,4.023c-.193.2-.293.238-.475-.027-.482-.7-1.007-1.37-1.5-2.061a1.424,1.424,0,0,1,.651-2.189c.854-.311,1.732-.556,2.595-.846.187-.063.241-.009.286.159a4.555,4.555,0,0,1,.117.941" transform="translate(-102.395 -75.171)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M102.146,41.24a1.381,1.381,0,0,1-1.434-1.35c-.036-.9,0-1.8-.016-2.7,0-.242.119-.246.288-.217a5.616,5.616,0,0,1,4.27,3.145c.048.095.129.211-.04.256a19.739,19.739,0,0,1-3.068.861" transform="translate(-91.275 -33.508)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M45.991,38.325c0,.4.01.805,0,1.207a1.451,1.451,0,0,1-1.854,1.4c-.859-.246-1.7-.56-2.554-.822-.216-.066-.23-.139-.135-.316a5.64,5.64,0,0,1,4.219-3.072c.281-.047.343.047.332.307-.018.433,0,.867-.005,1.3" transform="translate(-37.518 -33.272)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.369,84.442c0-1.28,0-1.28,1.188-.9.573.183,1.15.355,1.716.556a1.426,1.426,0,0,1,.713,2.193c-.5.709-1.029,1.392-1.525,2.1-.173.247-.276.187-.448.012a5.571,5.571,0,0,1-1.644-3.962" transform="translate(-32.967 -75.526)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M68.74,118.709a6.052,6.052,0,0,1-2.545-.6c-.261-.126-.309-.222-.125-.467.511-.678.989-1.381,1.5-2.062a1.427,1.427,0,0,1,2.281-.064c.568.711,1.068,1.475,1.612,2.206.169.226.022.286-.135.37a5.585,5.585,0,0,1-2.585.621" transform="translate(-59.789 -104.217)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M86.393,87.316a.956.956,0,1,1,.02-1.913.952.952,0,0,1,.936.947.931.931,0,0,1-.957.965" transform="translate(-77.444 -77.414)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.634,38.118c0-.511,0-1.022,0-1.533,0-.122-.067-.29.169-.3s.2.136.2.274q0,1.557,0,3.113c0,.135.03.31-.171.318-.242.01-.2-.188-.2-.335,0-.511,0-1.022,0-1.533" transform="translate(-84.87 -32.887)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M62.038,111.55c-.028.045-.07.125-.124.2q-.9,1.239-1.806,2.474c-.071.1-.109.279-.311.139s-.08-.228,0-.34q.915-1.256,1.83-2.513c.074-.1.144-.212.293-.176.084.02.122.088.114.218" transform="translate(-54.11 -100.911)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M108.867,114.172a1.351,1.351,0,0,1-.158.129c-.148.091-.189-.054-.245-.13-.4-.547-.8-1.1-1.2-1.65-.227-.313-.45-.629-.68-.94-.071-.1-.115-.193-.026-.29a.171.171,0,0,1,.281.025q1,1.363,2,2.735a.464.464,0,0,1,.033.12" transform="translate(-96.543 -100.824)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M42.54,76.915c-.043.175-.143.194-.285.149q-1.558-.5-3.118-1c-.122-.039-.18-.113-.133-.244s.152-.133.264-.1c1.024.332,2.048.667,3.073,1,.115.037.193.087.2.2" transform="translate(-35.341 -68.616)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M118.295,76.193c.037.22-.117.2-.2.229q-1.472.486-2.949.959c-.13.042-.3.1-.359-.079s.12-.222.248-.263c.984-.318,1.969-.63,2.953-.948.161-.052.315-.124.31.1" transform="translate(-104.036 -68.925)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M131.4,87.359a5.253,5.253,0,0,1-1.37,3.546c-.192.221-.32.251-.464-.025a.86.86,0,0,0-.164-.223c-.25-.237-.2-.423.014-.682a3.932,3.932,0,0,0,.909-2.8c-.017-.326.054-.457.366-.546.691-.2.687-.215.708.5,0,.077,0,.155,0,.232" transform="translate(-117.141 -78.42)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.369,40.549c0-.62,0-.621.551-.478a5.32,5.32,0,0,1,3.313,2.493c.145.238.107.344-.157.4a1.041,1.041,0,0,0-.263.086c-.337.17-.523.07-.73-.256a3.69,3.69,0,0,0-2.228-1.63c-.414-.109-.585-.269-.487-.611" transform="translate(-93.675 -36.252)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M49.112,40.483c.126.438-.033.591-.4.684a3.982,3.982,0,0,0-2.371,1.75c-.067.1-.082.3-.277.247a6.336,6.336,0,0,1-.865-.309c-.084-.039.029-.167.07-.238a5.208,5.208,0,0,1,3.591-2.626c.571-.12.13.4.256.492" transform="translate(-40.94 -36.233)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.306,86a.316.316,0,0,1,.074-.009c.905.255.9.255.95,1.182a4.063,4.063,0,0,0,.94,2.372c.079.1.269.175.16.339a5.8,5.8,0,0,1-.56.731c-.06.065-.149-.074-.206-.136A5.173,5.173,0,0,1,39.306,86" transform="translate(-35.565 -77.947)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M72.159,138.621a5.172,5.172,0,0,1-2.245-.486c-.2-.086-.277-.176-.107-.361a.926.926,0,0,0,.161-.226c.174-.374.392-.417.8-.267a3.818,3.818,0,0,0,2.726.007c.443-.168.627-.054.876.3s.223.489-.175.633a5.447,5.447,0,0,1-2.031.4" transform="translate(-63.193 -124.366)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="17.88" height="17.924" transform="translate(0 0)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />

    );
}

export function LogoSmall({ width = 200, height = 60 }) {
    return (
        <Icon
            component={
                () => (
                    <svg id="Group_11027" data-name="Group 11027" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 168.686 58.702" fontSize={50}>
                        <path id="Path_160" data-name="Path 160" d="M7.359,14.819c.98-3.79,1.982-7.574,2.927-11.372A3.22,3.22,0,0,1,12.774.771a3.859,3.859,0,0,1,3.77,1.076,2.8,2.8,0,0,1,.814,2.9,42.788,42.788,0,0,0-1.2,5.621c.528-.738,1.06-1.474,1.585-2.215q2.344-3.308,4.686-6.619a3.343,3.343,0,0,1,4.94-.786,3.3,3.3,0,0,1,.83,4.838q-3.861,5.445-7.72,10.89c-.172.243-.333.493-.581.861a23.769,23.769,0,0,1,7.279.992,21.276,21.276,0,0,1,8.816,5.441c.5.5.655.782.017,1.342-1.311,1.15-2.543,2.4-3.764,3.644-.493.5-.749.6-1.32.006a13.739,13.739,0,0,0-20.054-.062A12.811,12.811,0,0,0,7.762,42.194a13.007,13.007,0,0,0,10.461,9.221A13.337,13.337,0,0,0,31.082,47.2c.408-.43.593-.433,1-.014,1.285,1.33,2.6,2.635,3.948,3.9.487.455.424.706-.011,1.133-5.923,5.81-12.95,7.855-20.915,5.583C7.173,55.532,2.292,50.123.42,42.065A18.757,18.757,0,0,1,0,37.782Q.016,24.21.008,10.637A6.967,6.967,0,0,1,.067,9.408,3.688,3.688,0,0,1,3.849,6.2a3.824,3.824,0,0,1,3.316,3.842c.053,1.573.011,3.15.011,4.725l.182.049" transform="translate(0 0)" fill="#003d78" />
                        <g id="Group_12" data-name="Group 12" transform="translate(11.655 29.552)">
                            <path id="Path_141" data-name="Path 141" d="M17.023,5.079a.348.348,0,0,0,.071.453,1.6,1.6,0,0,1,.418,1.311.427.427,0,0,0,.062.3,1.5,1.5,0,0,1,.163,1.446c-.08.187.131.274.127.411a2.165,2.165,0,0,1-.147,1.216c-.185.292.041.621-.08.961a7.475,7.475,0,0,1-.808,1.953A13.829,13.829,0,0,1,16,14.461,9.356,9.356,0,0,1,14.7,15.782a6.16,6.16,0,0,1-1.579,1.078c-.578.187-1.074.632-1.683.644-.564.012-1.03.375-1.587.332a8.113,8.113,0,0,1-1.879.013c-.572-.188-1.211-.056-1.72-.371-.485-.3-1.107-.277-1.525-.652-.39-.35-.9-.481-1.282-.827a9.25,9.25,0,0,1-1.325-1.291,6.4,6.4,0,0,1-1.1-1.616C.834,12.531.4,12.044.4,11.452.391,10.93.062,10.51.078,10c.007-.233-.147-.474.078-.692.054-.052.017-.168-.017-.262A1.527,1.527,0,0,1,.207,7.639C.323,7.457.127,7.3.172,7.177A3.969,3.969,0,0,1,.618,5.9c.2-.258.146-.718.361-1.034.117-.172.109-.461.427-.468.044,0,.1-.144.126-.229a1.706,1.706,0,0,1,.9-1.16.438.438,0,0,0,.23-.215,1.542,1.542,0,0,1,1.046-.948.492.492,0,0,0,.229-.2A1.576,1.576,0,0,1,5.19.94c.086,0,.214-.025.251-.083A1.181,1.181,0,0,1,6.476.387c.534-.014.969-.359,1.5-.326.2.013.414-.126.6.087.054.061.19,0,.3-.03A1.609,1.609,0,0,1,10.247.19c.064.04.168.092.216.066a1.191,1.191,0,0,1,1.171.17c.432.243.933.249,1.336.52.2.132.487.146.538.467.011.067.159.143.255.162.523.1.793.527,1.173.825a8.316,8.316,0,0,1,1.4,1.549,7.954,7.954,0,0,1,.689,1.129" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M35.195,28.933a6.24,6.24,0,1,1-6.228,6.262,6.209,6.209,0,0,1,6.228-6.262" transform="translate(-26.257 -26.226)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,39a5.845,5.845,0,1,1,5.859,5.842A5.859,5.859,0,0,1,33.188,39" transform="translate(-30.083 -30.051)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M116.891,84.054a5.639,5.639,0,0,1-1.671,4.023c-.193.2-.293.238-.475-.027-.482-.7-1.007-1.37-1.5-2.061a1.424,1.424,0,0,1,.651-2.189c.854-.311,1.732-.556,2.595-.846.187-.063.241-.009.286.159a4.555,4.555,0,0,1,.117.941" transform="translate(-102.395 -75.171)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M102.146,41.24a1.381,1.381,0,0,1-1.434-1.35c-.036-.9,0-1.8-.016-2.7,0-.242.119-.246.288-.217a5.616,5.616,0,0,1,4.27,3.145c.048.095.129.211-.04.256a19.739,19.739,0,0,1-3.068.861" transform="translate(-91.275 -33.508)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M45.991,38.325c0,.4.01.805,0,1.207a1.451,1.451,0,0,1-1.854,1.4c-.859-.246-1.7-.56-2.554-.822-.216-.066-.23-.139-.135-.316a5.64,5.64,0,0,1,4.219-3.072c.281-.047.343.047.332.307-.018.433,0,.867-.005,1.3" transform="translate(-37.518 -33.272)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.369,84.442c0-1.28,0-1.28,1.188-.9.573.183,1.15.355,1.716.556a1.426,1.426,0,0,1,.713,2.193c-.5.709-1.029,1.392-1.525,2.1-.173.247-.276.187-.448.012a5.571,5.571,0,0,1-1.644-3.962" transform="translate(-32.967 -75.526)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M68.74,118.709a6.052,6.052,0,0,1-2.545-.6c-.261-.126-.309-.222-.125-.467.511-.678.989-1.381,1.5-2.062a1.427,1.427,0,0,1,2.281-.064c.568.711,1.068,1.475,1.612,2.206.169.226.022.286-.135.37a5.585,5.585,0,0,1-2.585.621" transform="translate(-59.789 -104.217)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M86.393,87.316a.956.956,0,1,1,.02-1.913.952.952,0,0,1,.936.947.931.931,0,0,1-.957.965" transform="translate(-77.444 -77.414)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.634,38.118c0-.511,0-1.022,0-1.533,0-.122-.067-.29.169-.3s.2.136.2.274q0,1.557,0,3.113c0,.135.03.31-.171.318-.242.01-.2-.188-.2-.335,0-.511,0-1.022,0-1.533" transform="translate(-84.87 -32.887)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M62.038,111.55c-.028.045-.07.125-.124.2q-.9,1.239-1.806,2.474c-.071.1-.109.279-.311.139s-.08-.228,0-.34q.915-1.256,1.83-2.513c.074-.1.144-.212.293-.176.084.02.122.088.114.218" transform="translate(-54.11 -100.911)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M108.867,114.172a1.351,1.351,0,0,1-.158.129c-.148.091-.189-.054-.245-.13-.4-.547-.8-1.1-1.2-1.65-.227-.313-.45-.629-.68-.94-.071-.1-.115-.193-.026-.29a.171.171,0,0,1,.281.025q1,1.363,2,2.735a.464.464,0,0,1,.033.12" transform="translate(-96.543 -100.824)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M42.54,76.915c-.043.175-.143.194-.285.149q-1.558-.5-3.118-1c-.122-.039-.18-.113-.133-.244s.152-.133.264-.1c1.024.332,2.048.667,3.073,1,.115.037.193.087.2.2" transform="translate(-35.341 -68.616)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M118.295,76.193c.037.22-.117.2-.2.229q-1.472.486-2.949.959c-.13.042-.3.1-.359-.079s.12-.222.248-.263c.984-.318,1.969-.63,2.953-.948.161-.052.315-.124.31.1" transform="translate(-104.036 -68.925)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M131.4,87.359a5.253,5.253,0,0,1-1.37,3.546c-.192.221-.32.251-.464-.025a.86.86,0,0,0-.164-.223c-.25-.237-.2-.423.014-.682a3.932,3.932,0,0,0,.909-2.8c-.017-.326.054-.457.366-.546.691-.2.687-.215.708.5,0,.077,0,.155,0,.232" transform="translate(-117.141 -78.42)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.369,40.549c0-.62,0-.621.551-.478a5.32,5.32,0,0,1,3.313,2.493c.145.238.107.344-.157.4a1.041,1.041,0,0,0-.263.086c-.337.17-.523.07-.73-.256a3.69,3.69,0,0,0-2.228-1.63c-.414-.109-.585-.269-.487-.611" transform="translate(-93.675 -36.252)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M49.112,40.483c.126.438-.033.591-.4.684a3.982,3.982,0,0,0-2.371,1.75c-.067.1-.082.3-.277.247a6.336,6.336,0,0,1-.865-.309c-.084-.039.029-.167.07-.238a5.208,5.208,0,0,1,3.591-2.626c.571-.12.13.4.256.492" transform="translate(-40.94 -36.233)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.306,86a.316.316,0,0,1,.074-.009c.905.255.9.255.95,1.182a4.063,4.063,0,0,0,.94,2.372c.079.1.269.175.16.339a5.8,5.8,0,0,1-.56.731c-.06.065-.149-.074-.206-.136A5.173,5.173,0,0,1,39.306,86" transform="translate(-35.565 -77.947)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M72.159,138.621a5.172,5.172,0,0,1-2.245-.486c-.2-.086-.277-.176-.107-.361a.926.926,0,0,0,.161-.226c.174-.374.392-.417.8-.267a3.818,3.818,0,0,0,2.726.007c.443-.168.627-.054.876.3s.223.489-.175.633a5.447,5.447,0,0,1-2.031.4" transform="translate(-63.193 -124.366)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="17.88" height="17.924" transform="translate(0 0)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />
    );
}

export function MenuFold() {
    return (
        <Icon
            component={
                () => (
                    <svg id="Group_11028" data-name="Group 11028" xmlns="http://www.w3.org/2000/svg" width="28.037" height="26.256" viewBox="0 0 28.037 26.256">
                        <rect id="Rectangle_73" data-name="Rectangle 73" width="5.607" height="19.626" rx="2.804" transform="translate(19.626 0.978) rotate(90)" fill="#003d78" />
                        <rect id="Rectangle_74" data-name="Rectangle 74" width="5.607" height="28.037" rx="2.804" transform="translate(28.037 19.67) rotate(90)" fill="#003d78" />
                        <rect id="Rectangle_75" data-name="Rectangle 75" width="5.607" height="28.037" rx="2.804" transform="translate(28.037 10.324) rotate(90)" fill="#f9423a" />
                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(5.434 9.633)" fill="#fff" stroke="#f9423a" strokeWidth="1">
                            <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                            <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                        </g>
                        <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(16.303 18.584)" fill="#fff" stroke="#003d78" strokeWidth="1">
                            <circle cx="3.836" cy="3.836" r="3.836" stroke="none" />
                            <circle cx="3.836" cy="3.836" r="3.336" fill="none" />
                        </g>
                        <g id="Ellipse_149" data-name="Ellipse 149" transform="translate(15.791)" fill="#fff" stroke="#003d78" strokeWidth="1">
                            <circle cx="3.836" cy="3.836" r="3.836" stroke="none" />
                            <circle cx="3.836" cy="3.836" r="3.336" fill="none" />
                        </g>
                    </svg>

                )
            }
        />
    );
}

export function TireIcon({ height = 45, width = 45 }) {
    return (
        <Icon
            spin={true}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44.634 44.744">
                        <g id="tire" transform="translate(0 -0.002)">
                            <path id="Path_141" data-name="Path 141" d="M42.493,12.679a.868.868,0,0,0,.177,1.13,3.981,3.981,0,0,1,1.044,3.273,1.066,1.066,0,0,0,.156.754,3.741,3.741,0,0,1,.408,3.609c-.2.466.326.685.318,1.026-.026,1.018.245,2.068-.368,3.036-.462.73.1,1.551-.2,2.4a18.659,18.659,0,0,1-2.017,4.875A34.523,34.523,0,0,1,39.93,36.1a23.355,23.355,0,0,1-3.23,3.3c-1.286.93-2.409,2.2-3.941,2.692-1.443.467-2.682,1.576-4.2,1.608-1.408.029-2.572.937-3.962.829-1.575-.122-3.21.518-4.69.032-1.429-.469-3.024-.139-4.294-.927-1.211-.751-2.763-.691-3.808-1.626-.975-.874-2.257-1.2-3.2-2.063A23.09,23.09,0,0,1,5.3,36.72c-.951-1.318-2.243-2.47-2.756-4.034-.461-1.4-1.54-2.62-1.555-4.1-.013-1.3-.833-2.35-.792-3.62.019-.581-.368-1.184.194-1.728.135-.13.042-.42-.043-.653a3.813,3.813,0,0,1,.17-3.517c.288-.456-.2-.847-.089-1.153.376-1.046.35-2.181,1.115-3.177.5-.645.365-1.793.9-2.581.292-.429.273-1.151,1.066-1.167.111,0,.258-.359.314-.571a4.26,4.26,0,0,1,2.244-2.9,1.092,1.092,0,0,0,.574-.536A3.848,3.848,0,0,1,9.253,4.622a1.228,1.228,0,0,0,.573-.507,3.934,3.934,0,0,1,3.129-1.766c.216,0,.535-.062.627-.208A2.949,2.949,0,0,1,16.165.968C17.5.934,18.585.071,19.9.153c.5.032,1.034-.315,1.5.217.135.153.474,0,.743-.075a4.017,4.017,0,0,1,3.434.181c.159.1.419.229.538.166a2.974,2.974,0,0,1,2.922.425c1.077.607,2.328.621,3.336,1.3.492.33,1.215.365,1.343,1.165.027.168.4.358.635.4,1.306.249,1.98,1.316,2.928,2.059A20.76,20.76,0,0,1,40.772,9.86a19.857,19.857,0,0,1,1.721,2.819" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M44.514,28.933A15.576,15.576,0,1,1,28.967,44.564,15.5,15.5,0,0,1,44.514,28.933" transform="translate(-22.203 -22.176)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,47.75A14.59,14.59,0,1,1,47.814,62.333,14.625,14.625,0,0,1,33.188,47.75" transform="translate(-25.438 -25.41)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M122.768,85.738A14.076,14.076,0,0,1,118.6,95.779c-.481.489-.731.593-1.186-.067-1.2-1.747-2.515-3.419-3.75-5.145a3.556,3.556,0,0,1,1.624-5.464c2.132-.777,4.324-1.389,6.477-2.111.468-.157.6-.023.715.4a11.372,11.372,0,0,1,.292,2.349" transform="translate(-86.584 -63.562)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M104.317,47.636a3.447,3.447,0,0,1-3.58-3.369c-.09-2.24-.007-4.485-.04-6.728-.009-.605.3-.613.719-.542a14.02,14.02,0,0,1,10.659,7.851c.121.237.321.526-.1.638-2.564.687-5,1.882-7.659,2.149" transform="translate(-77.18 -28.333)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M52.876,40.748c0,1,.024,2.01-.005,3.014a3.621,3.621,0,0,1-4.629,3.483c-2.144-.614-4.24-1.4-6.374-2.051-.54-.166-.575-.348-.338-.79a14.079,14.079,0,0,1,10.531-7.668c.7-.118.856.118.828.767-.046,1.08-.012,2.163-.013,3.245" transform="translate(-31.725 -28.134)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.37,86.119c-.006-3.195-.005-3.2,2.965-2.25,1.431.456,2.87.886,4.285,1.389A3.561,3.561,0,0,1,45.4,90.733c-1.238,1.771-2.569,3.476-3.806,5.247-.431.618-.689.468-1.118.031a13.906,13.906,0,0,1-4.1-9.891" transform="translate(-27.876 -63.862)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M72.9,124.3a15.108,15.108,0,0,1-6.353-1.509c-.652-.315-.771-.555-.312-1.166,1.275-1.693,2.468-3.447,3.733-5.147a3.562,3.562,0,0,1,5.7-.159c1.418,1.775,2.666,3.683,4.025,5.506.421.565.054.714-.337.924A13.941,13.941,0,0,1,72.9,124.3" transform="translate(-50.557 -88.123)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M87.824,90.179a2.388,2.388,0,1,1,.05-4.775,2.376,2.376,0,0,1,2.337,2.365,2.324,2.324,0,0,1-2.388,2.41" transform="translate(-65.486 -65.459)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.642,40.867c0-1.276-.009-2.552.007-3.828,0-.3-.168-.725.421-.756.568-.03.5.34.5.683q0,3.886,0,7.772c0,.338.075.773-.428.794-.6.025-.494-.469-.5-.837-.008-1.276,0-2.552,0-3.828" transform="translate(-71.765 -27.808)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M65.546,111.887c-.069.112-.176.313-.308.5q-2.248,3.092-4.507,6.176c-.178.242-.273.7-.776.347-.468-.324-.2-.569.005-.85q2.285-3.136,4.569-6.274c.185-.254.359-.529.733-.44.209.05.3.219.285.545" transform="translate(-45.755 -85.327)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M112.4,118.575a3.371,3.371,0,0,1-.394.323c-.369.227-.471-.135-.611-.325-1.009-1.367-2-2.743-3-4.118-.567-.781-1.124-1.569-1.7-2.346-.177-.239-.288-.481-.065-.723a.428.428,0,0,1,.7.062q2.508,3.4,4.984,6.828a1.158,1.158,0,0,1,.083.3" transform="translate(-81.635 -85.254)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M47.856,78.737c-.108.436-.356.485-.711.371q-3.89-1.259-7.785-2.506c-.3-.1-.45-.282-.332-.609s.379-.333.659-.242c2.557.829,5.111,1.664,7.67,2.486.287.092.482.216.5.5" transform="translate(-29.884 -58.019)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M123.566,76.426c.092.548-.291.5-.508.57q-3.675,1.214-7.361,2.395c-.324.1-.751.254-.9-.2s.3-.553.619-.656c2.456-.793,4.916-1.573,7.371-2.367.4-.13.786-.308.775.255" transform="translate(-87.971 -58.281)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M134.639,88.624a13.112,13.112,0,0,1-3.419,8.852c-.48.551-.8.626-1.159-.061a2.146,2.146,0,0,0-.41-.558c-.624-.592-.5-1.055.035-1.7a9.816,9.816,0,0,0,2.269-6.984c-.042-.814.134-1.141.915-1.364,1.726-.492,1.714-.536,1.768,1.238.006.193,0,.386,0,.579" transform="translate(-99.053 -66.31)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.409,41.38c0-1.548,0-1.55,1.376-1.193a13.28,13.28,0,0,1,8.271,6.224c.361.594.267.859-.391.989a2.6,2.6,0,0,0-.657.214c-.84.423-1.306.176-1.822-.64a9.21,9.21,0,0,0-5.561-4.07c-1.034-.271-1.459-.671-1.216-1.525" transform="translate(-79.21 -30.653)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M55.019,41.246c.315,1.092-.083,1.475-1.007,1.709a9.94,9.94,0,0,0-5.919,4.368c-.168.252-.2.76-.692.616a15.817,15.817,0,0,1-2.16-.771c-.211-.1.072-.416.174-.595a13,13,0,0,1,8.965-6.556c1.426-.3.324,1,.639,1.229" transform="translate(-34.618 -30.637)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.411,86.015a.789.789,0,0,1,.185-.021c2.26.636,2.243.637,2.371,2.951a10.141,10.141,0,0,0,2.347,5.922c.2.243.671.436.4.847a14.467,14.467,0,0,1-1.4,1.824c-.149.163-.372-.185-.514-.339a12.912,12.912,0,0,1-3.391-11.184" transform="translate(-30.073 -65.909)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M75.817,140.747a12.911,12.911,0,0,1-5.6-1.213c-.493-.214-.691-.439-.268-.9a2.312,2.312,0,0,0,.4-.564c.434-.934.978-1.041,1.985-.666a9.531,9.531,0,0,0,6.805.018c1.107-.419,1.566-.134,2.187.741s.556,1.22-.436,1.581a13.6,13.6,0,0,1-5.071,1" transform="translate(-53.435 -105.16)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="44.633" height="44.745" transform="translate(0 0.001)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />
    );
}